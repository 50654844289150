// React
import { useRouter } from 'next/router';
import { useState } from 'react';

// MUI
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Formik, Yup
import { useFormik } from 'formik';
import * as Yup from "yup";

// Local
import { URL } from '../../src/config';
import { api_post, cookieSet, log } from '../../src/utils';
import FormContainer from '../Reusable/FormContainer';
import IconLink from '../Reusable/IconLink';

export default function LoginForm() {
  const router = useRouter();

  // Operational states
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  function handleSubmit(values) {
    setLoading(true)
    setServerError("")

    // Connect to API
    api_post(URL.API.LOGIN, values)
      .then((res) => {
        if (res.data.success === true) {
          cookieSet("jwt", res.data.jwt)
          router.push(URL.WEB.INDEX)
        }
        else {
          setServerError(res.data.error || "")
        }
      })
      .catch(function (error) {
        log(error)
        setServerError("Error during operation.")
      })
      .then(() => { // finally
        setLoading(false)
      })
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Required").email("Invalid Email Address"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: handleSubmit
  })

  return (
    <FormContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            disabled={loading}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"

            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            disabled={loading}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          {
            serverError && <FormLabel error label="test">{serverError}</FormLabel>
          }
          <Button
            type="submit"
            fullWidth
            disabled={loading}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
            {loading && <CircularProgress className='button-spinner' size={15} />}
          </Button>
          <Grid container>
            <Grid item xs>
              <IconLink href={URL.WEB.PASSWORD_RESET_REQUEST}>
                Forgot password?
              </IconLink>
            </Grid>
            <Grid item>
              <IconLink href={URL.WEB.SIGNUP}>
                Don't have an account? Sign Up
              </IconLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </FormContainer>
  );
}