import Container from '@mui/material/Container';
import Grow from '@mui/material/Grow';
import { useTheme } from '@mui/material/styles'

export default function FormContainer({ children, fitContent = false }) {
    const theme = useTheme(); // Theme variables through MUI theme object

    return (
        <Grow in={true}>
            <Container component="main" maxWidth={fitContent ? "" : "xs"} className={fitContent ? 'form-container-fitting' : 'form-container'}
                sx={{
                    boxShadow: theme.custom.formShadow,
                }}>
                {children}
            </Container>
        </Grow>
    );
}