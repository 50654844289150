import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';

import Layout from '../components/Layout/Layout'
import LoginForm from '../components/User/LoginForm';
import AppContext from '../src/AppContext';
import { URL } from '../src/config';

export default function Login() {
  const { user, setUser } = useContext(AppContext);
  const router = useRouter();

  return (
    <Layout pageTitle={URL.TITLE.LOGIN} centerContentVertically>
      <LoginForm />
    </Layout>
  );
}
